import apiClient from "@/utils/api/ApiClient";
import _ from "lodash";
import { useUserStore } from "@/utils/store/user";

export type BaseResponse<T = unknown> = Promise<Response>;

export interface LoginResponse extends BaseResponse {
  data: { token: string };
}
// 获取jwt
function getInnerJwt() {
  const userStore = useUserStore();
  const token = userStore.currentUser.token;
  return `Bearer ${token}`;
}

// line登录core接口
export function lineCust({
  sub,
  name,
  email,
}: {
  sub: string;
  name: string;
  email: string;
}): Promise<LoginResponse> {
  const requestData: { [key: string]: any } = {};

  const propertiesToSet: [string | undefined, any | null][] = [
    ["sub", sub],
    ["name", name],
    ["email", email],
  ];

  propertiesToSet.forEach(([property, value]) => {
    if (value !== null && value !== undefined) {
      _.set(requestData, property, value);
    }
  });
  // console.info("requestData====", requestData);
  return apiClient
    .request(
      {
        url: "/core-api/biz/line/cust",
        method: "PUT",
        headers: {
          Authorization: getInnerJwt(),
          "Content-Type": "application/json",
        },
        data: requestData,
      },
      { isTransformResult: false },
    )
    .catch((e) => {
      console.info("[< e==== >]", e);
      return Promise.reject(e);
    });
}

// 电池列表页面
export function getBatteryList(requestData) {
  return apiClient
    .request(
      {
        url: "/core-api/biz/dv-app-query",
        method: "PUT",
        headers: {
          Authorization: getInnerJwt(),
        },
        data: requestData,
      },
      { isTransformResult: false },
    )
    .catch((e) => {
      return Promise.reject(e);
    });
}

// 单个文件上传接口
export function uploadSingleFile(formData: FormData) {
  return apiClient
    .request(
      {
        url: "/core-api/oss/upload",
        method: "POST",
        headers: {
          Authorization: getInnerJwt(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
      { isTransformResult: false },
    )
    .catch((e) => {
      console.error("Error:", e);
      return Promise.reject(e);
    });
}

// 批量文件上传
export function uploadMultipleFiles(formData: FormData) {
  return apiClient
    .request(
      {
        url: "/core-api/oss/upload/batch",
        method: "POST",
        headers: {
          Authorization: getInnerJwt(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
      { isTransformResult: false },
    )
    .then((e) => {
      return e;
    })
    .catch((e) => {
      console.error("Error:", e);
      return Promise.reject(e);
    });
}

// 创建SN
export function createSn(requestData) {
  return apiClient
    .request(
      {
        url: "/core-api/biz/sn/content",
        method: "POST",
        headers: {
          Authorization: getInnerJwt(),
          "Content-Type": "application/json",
        },
        data: requestData,
      },
      { isTransformResult: true },
    )
    .catch((e) => {
      return Promise.reject(e);
    });
}
// 修改SN
export function updSn(requestData) {
  return apiClient
    .request(
      {
        url: "/core-api/biz/sn/content",
        method: "PUT",
        headers: {
          Authorization: getInnerJwt(),
        },
        data: requestData,
      },
      { isTransformResult: false },
    )
    .catch((e) => {
      return Promise.reject(e);
    });
}

// 修改SN
export function getSn(snId) {
  return apiClient
    .request(
      {
        url: `/core-api/biz/sn/content/${snId}`,
        method: "GET",
        headers: {
          Authorization: getInnerJwt(),
        },
      },
      { isTransformResult: false },
    )
    .catch((e) => {
      return Promise.reject(e);
    });
}

//获得jwt
// State: "state",
// Code: "code",
// Seesion_Dict: {
//   PJID: "80173",
// },

export function getJwt() {
  return new Promise((resolve) => {
    const requestData = {
      Cloud_key: "battory-dev",
      Grand_type: "client_credentials",
      Secret: "1F3F9F99C50B4DD487E88277DBB44844",
      Response_type: "jwt",
      Code: "123",
      State: "123",
    };
    return apiClient
      .request(
        {
          url: "/jwtApi/jwttoken",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: requestData,
        },
        { isTransformResult: false },
      )
      .then((response) => {
        resolve(response.Content);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  });
}
