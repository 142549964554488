<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { jwtDecode } from "jwt-decode";

import liff from "@line/liff";

import { useCartStore } from "@/utils/store/cart";
import { useQuasar } from "quasar";

const $q = useQuasar();

const cart = useCartStore();

document.title = import.meta.env.VITE_APP_TITLE;

const isCartDisabled = computed(() => {
  return cart.qty <= 0;
});

const name = computed(() => {
  const idToken = liff.getIDToken();
  if (!idToken) {
    return "";
  }
  const decoded = jwtDecode(idToken);
  console.log(decoded);
  if (decoded.name) {
    return decoded.name;
  }
  return "";
});

const message = ref("");
const error = ref("");

liff
  .init({
    liffId: import.meta.env.VITE_LIFF_ID,
  })
  .then(() => {
    message.value = "LIFF init succeeded.";
  })
  .catch((e: Error) => {
    message.value = "LIFF init failed.";
    error.value = `${e}`;
  });

const state = reactive({
  dialogVisible: false,
});

const profile = () => {
  state.dialogVisible = true;
};

const logout = () => {
  liff.logout();
  // console.log(router.currentRoute.value.path)
  window.location.href = "/login";
};

const checkout = async () => {
  await cart.checkout();
  $q.notify({
    position: "center",
    message: "checkout succeeded",
    timeout: 1000,
  });
  // $q.notify('checkout succeeded')
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.page-container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 60px; /* 60px为q-footer的高度 */
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.cart-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-info q-icon {
  margin-right: 5px;
}

.cart-quantity {
  margin: 0 10px;
}

.cart-amount {
  margin-left: 10px;
}
</style>
