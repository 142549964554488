import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import type { App } from "vue";
import { createRouterGuards, RouterEnum } from "./router-guards";

const whiteNameList = [
  "icons",
  "error",
  "error-404",
  RouterEnum.REDIRECT_LINE_NAME, // 添加到白名单中
  RouterEnum.LOGIN_NAME,
  RouterEnum.NOT_FOUND_NAME,
  RouterEnum.CARD_DETAIL,
] as const;

export type WhiteNameList = typeof whiteNameList;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/pages/home.vue"), // 使用 home.vue 作为布局组件
    children: [
      {
        path: "/list/listPage", // 根路径显示为列表页面
        name: RouterEnum.LIST_PAGE,
        component: () => import("@/pages/list/listPage.vue"),
      },
      {
        path: "/list/listPage/:id", // 根路径显示为列表页面
        name: RouterEnum.CARD_DETAIL,
        component: () => import("@/pages/list/cardDetail.vue"),
      },
      {
        path: "/new/newPage",
        name: RouterEnum.NEW_PAGE,
        component: () => import("@/pages/new/newPage.vue"),
      },
      {
        path: "/setting/settingPage",
        name: RouterEnum.SETTING_PAGE,
        component: () => import("@/pages/setting/settingPage.vue"),
        // props: route => ({ query: route.query.q }),
      },
      {
        path: "/404",
        name: RouterEnum.NOT_FOUND_NAME,
        component: () => import("@/pages/404.vue"),
      },
    ],
  },
  {
    path: "/redirect/line", // 添加对应的路径
    name: RouterEnum.REDIRECT_LINE_NAME, // 设置名称
    component: () => import("@/pages/RedirectLine.vue"), // 创建一个对应的组件来处理重定向
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
});

export async function setupRouter(app: App) {
  createRouterGuards(router, whiteNameList);
  app.use(router);
  await router.isReady();
}

export default router;
