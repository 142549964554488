const enInfo = {
  html: {
    home_title: 'Home',
    home_content: 'Home',
  },
  common: {
    login: 'Log in with LINE',
    logout: 'logout from LINE',
    profile: 'Profile',
    order_history: 'Order history',
    settings: 'Settings',
    help_feedback: 'Help & Feedback',
    save: 'Save',
    cancel: 'Cancel',
    my_info: 'My Info',
  },
  title: {
    customer_select: 'Customer Select',
  },
  message: {},
}
export default enInfo
