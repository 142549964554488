const cnInfo = {
  html: {
    home_title: 'Home',
    home_content: 'Home',
  },
  common: {
    login: '用LINE帐号登录',
    logout: 'LINE帐号登出',
    order_history: '订单历史',
    profile: '个人信息',
    settings: '设置',
    help_feedback: '帮助和反馈',
    save: '保存',
    cancel: '取消',
    my_info: 'My Info',
  },
  title: {
    customer_select: '客户选择',
  },
  message: {},
}
export default cnInfo
