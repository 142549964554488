import { defineStore } from 'pinia'
import { Cart, CartLine } from '@/dto/cart'
import _ from 'lodash'
import dayjs from 'dayjs'
import apiClient from '@/utils/api/ApiClient'
import liff from '@line/liff'
import { jwtDecode } from 'jwt-decode'

// create a function to use pinia to define a new store for shopping cart
export const useCartStore = defineStore({
    // unique id of the store across your application
    id: 'cart',
    // a function that returns a fresh state
    // state: (): Cart[] => (<Cart[]>[]),
    state: (): Cart =>
        <Cart>{
            cartHeadId: 0,
            cartLines: [],
        },
    // optional getters
    getters: {
        count(): number {
            return this.cartLines.length
        },
        qty(): number {
            return _.reduce(this.cartLines, (acc, cur) => acc + cur.qty, 0)
        },
        total(): number {
            return _.reduce(this.cartLines, (acc, cur) => acc + cur.qty * cur.skuPrice, 0)
        },
        qtyBySkuId: state => {
            return (skuId: number) => {
                let cartLine = state.cartLines.find(cartLine => cartLine.skuId === skuId)
                if (cartLine) {
                    return cartLine.qty
                } else return 0
            }
        },
        allQty(): number {
            return _.reduce(this.cartLines, (acc, cur) => acc + cur.qty, 0)
        },
        allPrice(): number {
            return _.reduce(this.cartLines, (acc, cur) => acc + cur.qty * cur.skuPrice, 0)
        },
    },
    // optional actions
    actions: {
        changeQty(skuId: number, skuPrice: number, qty: number) {
            qty = Number(qty)
            let cartLine = _.find(this.cartLines, { skuId: skuId })
            if (!cartLine) {
                cartLine = {
                    skuId: skuId,
                    skuPrice: skuPrice,
                    qty: qty,
                }
                this.cartLines.push({
                    skuId,
                    skuPrice,
                    qty,
                })
            } else {
                cartLine.qty = qty
            }
        },
        async checkout() {
            const idToken = liff.getIDToken()
            if (!idToken) {
                return
            }
            const decoded = jwtDecode(idToken)
            console.log(decoded)
            if (!decoded) {
                return
            }
            const unitNo = decoded.sub
            const unitName = decoded.name

            try {
                const response = await apiClient.post(
                    'https://proto.netsdl.com/partner/cust/eu',
                    {
                        unitNo: unitNo,
                        unitName: unitName,
                        coId: '1475749077213603759',
                        cat2: 'seikatsumura',
                    },
                    {
                        headers: {
                            Authorization: ` ${import.meta.env.VITE_DUMMY_TOKEN}`,
                            'Content-Type': 'application/json',
                        },
                    }
                )
                if (_.get(response, 'data.content.errorCode', 0) !== 0) {
                    console.log(response)
                    return
                } else {
                    const unit = _.get(response, 'data.content')
                    if (!unit) return

                    const orderLines = _.map(this.cartLines, cartLine => {
                        return {
                            skuId: cartLine.skuId,
                            reqQty: cartLine.qty,
                            invType: 'A',
                            pPrice: cartLine.skuPrice, //单价
                            pAmt: cartLine.qty * cartLine.skuPrice, // req_Qty * pPrice
                            priceEnum: 3, //固定值
                        }
                    })

                    const orderAmt = _.reduce(orderLines, (acc, cur) => acc + cur.pAmt, 0)

                    const now = dayjs() // 現在の日付情報を取得

                    const json_data = {
                        enableOrderLine: true, //固定值
                        enableHeadScmShipping: true,
                        orderDate: now.format('YYYY/MM/DD'), //当前日期
                        boType: 'B2C', //固定值
                        shippingType: 'SP', //固定值
                        importType: 'IFS', //固定值
                        rtn: 1, //固定值
                        suppId: '1481566415902079806', //channel//固定值
                        storeId: '1475655138096130869', //store//固定值
                        coId: '1475749077213603759', //store//固定值
                        custId: unit.unitId, // cu ，line id 交换
                        orderAmt: orderAmt, // sum pamt
                        scmShippingShipToAdd1: '中新井',
                        scmShippingShipToAdd2: '1-2-3',
                        scmShippingShipToMobile: '08012345678',
                        scmShippingShipToName: '',
                        scmShippingShipToProvince: '埼玉県',
                        scmShippingShipToCity: '所沢市',
                        ScmShippingShipToEmail: 'xxxx@xxxx.com',
                        scmShippingShipToTel: '04‐2998-0000',
                        scmShippingShipToZip: '3590041',
                        orderLines: orderLines,
                    }
                    console.log(json_data)

                    const responseOrder = await apiClient.post(
                        'https://proto.netsdl.com/api/scm-order',
                        json_data,
                        {
                            headers: {
                                Authorization: ` ${import.meta.env.VITE_DUMMY_TOKEN}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    if (_.get(responseOrder, 'data.content.errorCode', 0) !== 0) {
                        console.log(_.get(responseOrder, 'data.content.errorMsg'))
                    } else {
                        console.log(_.get(responseOrder, 'data.content'))
                        this.cartLines = []
                    }
                }
            } catch (error) {
                console.log(error)
                return
            }
        },
        async checkout2(project_no: string, customer_id: string) {
            const idToken = liff.getIDToken()
            if (!idToken) {
                return
            }
            // const decoded = jwtDecode(idToken)
            // console.log(decoded)
            // if (!decoded) {
            //     return
            // }
            // const unitNo = decoded.sub
            // const unitName = decoded.name
            //
            // console.log(unitNo, unitName)

            const orderLines = _.map(this.cartLines, cartLine => {
                return {
                    skuId: cartLine.skuId,
                    reqQty: cartLine.qty,
                    invType: 'A',
                    pPrice: cartLine.skuPrice, //单价
                    pAmt: cartLine.qty * cartLine.skuPrice, // req_Qty * pPrice
                    priceEnum: 3, //固定值
                }
            })

            const orderAmt = _.reduce(orderLines, (acc, cur) => acc + cur.pAmt, 0)

            const now = dayjs() // 現在の日付情報を取得

            const json_data = {
                enableOrderLine: true, //固定值
                enableHeadScmShipping: true,
                orderDate: now.format('YYYY/MM/DD'), //当前日期
                boType: 'B2C', //固定值
                shippingType: 'SP', //固定值
                importType: 'IFS', //固定值
                rtn: 1, //固定值
                // "suppId": "1481566415902079806",//channel//固定值
                // "storeId": "1475655138096130869",//store//固定值
                // "coId": "1475749077213603759",//store//固定值
                custId: customer_id, // cu ，line id 交换
                orderAmt: orderAmt, // sum pamt
                scmShippingShipToAdd1: '芝大門',
                scmShippingShipToAdd2: '2-2-1',
                scmShippingShipToMobile: '08012345678',
                scmShippingShipToName: '',
                scmShippingShipToProvince: '東京都',
                scmShippingShipToCity: '港区',
                ScmShippingShipToEmail: 'xxxx@xxxx.com',
                scmShippingShipToTel: '03‐1234-5678',
                scmShippingShipToZip: '1050012',
                orderLines: orderLines,
            }
            console.log(json_data)
            console.log(import.meta.env.VITE_DUMMY_TOKEN)

            const responseOrder = await apiClient
                .request(
                    {
                        url: '/edm-server/api/v1/core/store/b2c_store/scm_order',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: { project_no: project_no, data: json_data },
                    },
                    { isTransformResult: true }
                )
                .catch(e => {
                    return Promise.reject(e)
                })

            if (_.get(responseOrder, 'data.content.errorCode', 0) !== 0) {
                console.log(_.get(responseOrder, 'data.content.errorMsg'))
            } else {
                console.log(_.get(responseOrder, 'data.content'))
                this.cartLines = []
            }
        },
    },
})
