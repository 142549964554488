import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { isFunction } from "@/utils/is";

// 定义请求选项接口
export interface RequestOptions {
  isTransformResult?: boolean; // 是否处理请求结果
  keyOfData?: string; // 数据中的键名
}

// 定义 Axios 转换器抽象类
export abstract class AxiosTransform {
  transformResponseData?: (
    res: AxiosResponse<any>,
    options: RequestOptions,
  ) => Promise<any>; // 响应数据转换方法返回 Promise
}

// 定义 API 客户端类
export class ApiClient {
  private axiosInstance: AxiosInstance;
  private transform: AxiosTransform;

  constructor(
    options: { headers: { "Content-type": string }; baseURL: string },
    transform: AxiosTransform,
  ) {
    this.axiosInstance = axios.create(options);
    this.transform = transform;
    this.setupInterceptors();
  }

  // 设置拦截器
  private setupInterceptors() {
    const { transformResponseData } = this.transform || {};

    // 响应拦截器
    this.axiosInstance.interceptors.response.use(
      (res: AxiosResponse) => {
        const isCancel = axios.isCancel(res);
        if (
          transformResponseData &&
          isFunction(transformResponseData) &&
          !isCancel
        ) {
          // 在响应拦截器中使用 resolve 和 reject 处理 Promise
          return transformResponseData(res, {}).catch((error) => {
            console.error("Error in transformResponseData:", error);
            return Promise.reject(error);
          });
        }
        // 返回处理后的响应
        return res;
      },
      (error: Error) => {
        return Promise.reject(error);
      },
    );
  }

  // 发送请求方法
  request<T = any>(
    config: AxiosRequestConfig,
    options?: RequestOptions,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request<any, AxiosResponse>(config)
        .then((res: AxiosResponse) => {
          const isCancel = axios.isCancel(res);
          if (
            this.transform.transformResponseData &&
            isFunction(this.transform.transformResponseData) &&
            !isCancel
          ) {
            // 在发送请求方法中使用 resolve 和 reject 处理 Promise
            this.transform
              .transformResponseData(res, options || {})
              .then((transformedData) => resolve(transformedData))
              .catch((error) => {
                console.error("Error in transformResponseData:", error);
                reject(error);
              });
          } else {
            reject(new Error("transformResponseData is not defined"));
          }
        })
        .catch((e: Error) => {
          reject(e);
        });
    });
  }
}

// 创建 Axios 转换器实例
const transform: AxiosTransform = {
  // 定义响应数据转换方法返回 Promise
  transformResponseData: (res: AxiosResponse<any>, options: RequestOptions) => {
    return new Promise((resolve, reject) => {
      const isTransformResult = options.isTransformResult ?? true;
      if (!isTransformResult) {
        resolve(res);
      }

      let data = res.data;
      const { keyOfData } = options;
      if (!keyOfData) resolve(data);
      if (!(keyOfData in data)) {
        resolve(data);
      }
      resolve(data[keyOfData]);
    });
  },
};

// 创建 API 客户端实例
const apiClient = new ApiClient(
  {
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      "Content-type": "application/json",
    },
  },
  transform,
);

// 导出 API 客户端实例
export default apiClient;
