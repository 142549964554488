import { createApp } from "vue";
import App from "./App.vue";

import { Loading, Notify, Quasar } from "quasar";
import quasarLang from "quasar/lang/ja";
import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";

import "@fortawesome/fontawesome-free/css/all.css";

import { createPinia } from "pinia";
import { setupRouter } from "./router";
import { setupLiff } from "@/utils/liff";
import { setupI18n } from "@/utils/i18n";
import { setupStore } from "@/utils/store";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(Quasar, {
  plugins: { Notify, Loading }, // 导入 Quasar 插件并添加到这里
  lang: quasarLang,
});

async function setupApp() {
  setupStore(app);
  // 挂载路由
  setupI18n(app);

  await setupLiff();
  await setupRouter(app);
  app.mount("#app");
}

setupApp();
