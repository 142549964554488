import { Router, RouteRecordRaw } from "vue-router";
import liff from "@line/liff";
import { useUserStore } from "@/utils/store/user";
import { getJwt, lineCust } from "@/utils/api/unit/user";

export enum RouterEnum {
  LOGIN_NAME = "Login",
  ABOUT_NAME = "About",
  NOT_FOUND_NAME = "NotFound",
  REDIRECT_LINE_NAME = "RedirectLine",
  NEW_PAGE = "new",
  LIST_PAGE = "list",
  SETTING_PAGE = "setting",
  CARD_DETAIL = "detail",
}

export async function createRouterGuards(
  router: Router,
  whiteNameList: RouteRecordRaw[],
) {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    console.info("[< to.name  >]", to.name);
    // 检查是否在免登录名单中
    if (whiteNameList.some((route) => route.name === to.name)) {
      next(); // 直接进入
    } else if (liff.isLoggedIn()) {
      // 用户已登录，尝试获取用户信息
      const decodedIDToken = liff.getDecodedIDToken();
      const sub = decodedIDToken.sub;
      const name = decodedIDToken.name;
      const email = decodedIDToken.email || "";

      getJwt().then((jwt) => {
        // Handle the JWT token here
        userStore.setToken(jwt);
        // console.log("JWT token:", jwt);
      });

      // try {
      // 调用 lineCust 接口获取用户信息
      const { content } = await lineCust({ sub, name, email });
      if (content) {
        // 设置当前用户信息
        userStore.setCurrentCustomer(content);
        // 如果用户已登录但尚未跳转到任何页面，则跳转到根目录页面
        if (to.name === undefined || to.name === null) {
          next({ name: RouterEnum.LIST_PAGE });
        } else {
          next(); // 继续导航到目标页面
        }
      } else {
        next({ name: RouterEnum.NOT_FOUND_NAME }); // 处理错误
      }
      // } catch (error) {
      //   console.error("请求出错：", error);
      //   next({ name: RouterEnum.NOT_FOUND_NAME }); // 请求出错时跳转到 NOT_FOUND 页面
      // }
    } else {
      // 用户未登录，进行登录操作
      const queryString = Object.keys(to.query)
        .map((key) => `${key}=${to.query[key]}`)
        .join("&");

      const host = window.location.host;
      const protocol = window.location.protocol;
      const nextUrl = encodeURIComponent(`${to.path}?${queryString}`);
      const redirectUri = `${protocol}//${host}/redirect/line?next=${nextUrl}`;
      liff.login({ redirectUri: redirectUri });
    }
  });
}
