import liff from '@line/liff'

export async function setupLiff() {
  await liff
    .init({ liffId: import.meta.env.VITE_LIFF_ID })
    .then(() => {
      console.log('LIFF init succeeded.')
    })
    .catch((e: Error) => {
      console.log('LIFF init error: ', e)
    })
}
