import { defineStore } from "pinia";
import { User } from "@/dto/user";
import { Customer } from "@/dto/customer";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    currentUser: {} as User,
    currentCustomer: undefined as Customer | undefined,
  }),
  getters: {},
  actions: {
    setToken(token: string | undefined) {
      this.currentUser.token = token;
    },
    setCurrentCustomer(currentCustomer: Customer | undefined) {
      this.currentCustomer = currentCustomer;
    },
  },
  persist: true,
});
