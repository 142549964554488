import { createI18n } from 'vue-i18n'
import type { createApp } from 'vue'
import cnInfo from './cn'
import enInfo from './en'
import jpInfo from './jp'

export enum LanguageTypes {
  CN = 'cn',
  EN = 'en',
  JP = 'jp',
}

const localeData = {
  legacy: false, // composition API
  locale: LanguageTypes.JP,
  messages: {
    [LanguageTypes.CN]: cnInfo,
    [LanguageTypes.EN]: enInfo,
    [LanguageTypes.JP]: jpInfo,
  },
}
export function setupI18n(app: ReturnType<typeof createApp>) {
  const i18n = createI18n(localeData)
  app.use(i18n)
}
