const jpInfo = {
  html: {
    home_title: 'Home',
    home_content: 'Home',
  },
  common: {
    login: 'ログイン',
    logout: 'ログアウト',
    profile: 'プロフィール',
    order_history: '注文履歴',
    settings: '設定',
    help_feedback: 'お問合せ',
    save: '保存',
    cancel: 'キャンセル',
    my_info: '会員情報',
  },
  title: {
    customer_select: '顧客選択',
  },
  message: {},
}
export default jpInfo
